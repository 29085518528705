import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

import LoadingComponent from "../../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
// import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { getGrupos } from "../../../../services/alumnos";
import { Stack } from "@mui/material";
import { Box, Button, Typography } from "@material-ui/core";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DataGridInnovattio from "../../../../components/DataGridInnovatiio";
import QuickSearchToolbar from "../../../../components/QuickSearchToolbar";
import { requestSearch } from "../../../../utils/regExp";
import { DialogGrupoForm } from "./DialogGrupoForm";
import SendIcon from "@mui/icons-material/Send";
import { DialogEntregaForm } from "./DialogEntregaForm";
// import { DialogEntregaForm } from "./DialogEntregaForm";


// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
  emptyImage: {
    width: "100%",
  },

  enlace: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
}));

export default function GruposContent() {
  const classes = useStyles();

  const [grupos, setGrupos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  // const [plantillaSeleccionadaId, setPlantillaSeleccionadaId] = useState("");

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const history = useHistory();

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando grupos");

    cargarGrupos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                              Cargar Plantillas                             */
  /* -------------------------------------------------------------------------- */
  const cargarGrupos = () => {
    const usuario_id = usuarioSeleccionado.id;

    getGrupos({usuario_id: usuarioSeleccionado.id}).then( response => {
      setLoading(false);
      response && setGrupos(response.grupos);
    })

  };
  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */

  const columnas = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "descripcion", headerName: "Descripción", flex: 1, minWidth: 370 },
    { field: "tutor_usuario_nombre", headerName: "Tutor", minWidth: 250 },
    {
      field: "comunidad_autonoma_nombre",
      headerName: "Comunidad",
      minWidth: 250,
    },
    {
      field: "es_reposicion",
      headerName: "Reposicion",
      minWidth: 250,
      renderCell: (params) => {
        return <div>{params.value === "1" ? "Si" : "No"}</div>;
      },
    },
    {
      field: "es_estabilizacion",
      headerName: "Estabilizacion",
      minWidth: 250,
      renderCell: (params) => {
        return <div>{params.value === "1" ? "Si" : "No"}</div>;
      },
    },
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 80,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<SendIcon />}
    //       label="Enviar difusión"
    //       onClick={() => enviarDifusion(params.id)}
    //       showInMenu
    //     />,
    //   ],
    // },
  ];

  /* -------------------------------------------------------------------------- */
  // const enviarDifusion = (id) => {
    // Swal.fire({
    //   title: "¿Realmente deseas enviar una difusión a este grupo?",
    //   showCancelButton: true,
    //   confirmButtonText: "Enviar",
    //   cancelButtonText: `Cancelar`,
    //   confirmButtonColor: "#afcb0d",
    //   cancelButtonColor: "#a5a5a5",
    //   icon: "warning",
    //   reverseButtons: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     setPlantillaSeleccionadaId(id);
    //     setOpenDialogoDifusion(true);
    //   } else if (result.isDenied) {
    //     //         //   Swal.fire('Changes are not saved', '', 'info')
    //   }
    // });
  // };
  // const handleCerrarModalDifusion = () => {
  //   setOpenDialogoDifusion(false);
  // };
  // const handleEnviarDifusion = (id) => {
  //   setPlantillaSeleccionadaId(id);
  //   setOpenDialogoDifusion(true);
  // };

  /* -------------------------------------------------------------------------- */
  /*                             handleGrupoDetalle                             */
  /* -------------------------------------------------------------------------- */
  const handleGrupoDetalle = (id) => {
    history.push("/grupos/detalle/" + id);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */

  const Contenido = () => {
    const [rows, setRows] = useState(grupos);
    const [searchText, setSearchText] = useState("");
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [openModalCreateEntrega, setOpenModalCreateEntrega] = useState(false);

    const handleOpenModalCreate = (valor) => {
      setOpenModalCreate(valor);
    };

    const handleOpenModalCreateEntrega = (valor) => {
      setOpenModalCreateEntrega(valor);
    };

    return (
      <>
        <Stack direction={"row"} spacing={2}>
          <Box mt={0} mb={0} mx={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Grupos de alumnos
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpenModalCreate(true)}
          >
            Añadir grupo
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpenModalCreateEntrega(true)}
          >
            Añadir entrega
          </Button>
        </Stack>

        
        <Box>
          <div style={{ height: 700, width: "100%", padding: "0 15px" }}>
            <DataGridInnovattio
              rows={rows}
              columns={columnas}
              rowHeight={30}
              components={{ Toolbar: QuickSearchToolbar }}
              onDoubleClick={(evento) => handleGrupoDetalle(evento.id)}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) =>
                    requestSearch(
                      grupos,
                      setSearchText,
                      setRows,
                      event.target.value
                    ),
                  clearSearch: () =>
                    requestSearch(grupos, setSearchText, setRows, ""),
                },
              }}
            />

            <DialogGrupoForm
              openModal={openModalCreate}
              setOpenModal={setOpenModalCreate}
              cargarGrupos={cargarGrupos}
            />

            <DialogEntregaForm
              openModal={openModalCreateEntrega}
              handleOpenModal={setOpenModalCreateEntrega}
            />

          </div>
        </Box>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
