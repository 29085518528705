import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
import { grupoCreate } from "../../../../services/alumnos";

export const DialogGrupoForm = ({ openModal, setOpenModal, cargarGrupos }) => {
  const [errorDescripcion, setErrorDescripcion] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  
  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  const handleCreateGrupo = () => {
    setErrorDescripcion(false)
    if( descripcion === '' ) {
      setErrorDescripcion(true)
      return null;
    }

    grupoCreate({
      usuario_id: usuarioSeleccionado.id,
      descripcion: descripcion
    }).then((response) => {
      cargarGrupos()
      setDescripcion( '' )
    });
    setOpenModal(false)
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      maxWidth={"md"}
    >
      <DialogTitle>Crear grupo</DialogTitle>
      <DialogContent style={{ width: "700px" }}>
        <Grid xs={12}>
          <FormControl variant="outlined" fullWidth error={errorDescripcion}>
            <TextField
              autoFocus
              id="descripcion"
              label="Descripción del grupo"
              type="text"
              fullWidth
              variant="standard"
              value={descripcion}
              onChange={(value) => {
                setDescripcion(value.target.value);
              }}
            />

            {errorDescripcion && (
              <FormHelperText>
                Es necesario escribir una descripción del grupo
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
        <Button onClick={() => handleCreateGrupo()}>Crear grupo</Button>
      </DialogActions>
    </Dialog>
  );
};
