import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer'
import { DataGridPro, GridToolbar, esES, useGridApiRef } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAlumnosRegistradosReducida } from '../../../services/alumnos';
import { NotificacionAlumnoService } from '../../../services/NotificacionesAlumnos.service';
  
const SeleccionarDestino = ({notificacionId, handleCerrarDialogDestinos}) => {
  const [alumnos, setAlumnos] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingMensaje, setLoadingMensaje] = useState('')
  const [selection, setSelection] = React.useState([]);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const apiRefDataGrid = useGridApiRef();

  useEffect(() => {
    setLoading(true)
    setLoadingMensaje("Cargando alumnos")

    const usuario_id = usuarioSeleccionado.id

    getAlumnosRegistradosReducida({ usuario_id }).then((response) => {

        const alumnosRegistrados = response.alumnos.filter( (item) => item.status_id === '94' )
        response && setAlumnos(alumnosRegistrados)

        setLoading(false)

    });

// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columnas = 
    [
        { field: 'id', hide: true, headerName: 'ID' },
        { 
            field: 'nombre', 
            headerName: 'Nombre', 
            minWidth: 150, 
            flex: 1, 
            renderCell: (celda) => {
                return celda.row.nombre
                // return <Link href={`/alumnos_tutor/detalle/${celda.row.id}`}>{celda.row.nombre }</Link>

            }
        },
        { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
        { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 100, flex: 1 },
        { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 10, flex: 1 },
        { field: 'tutor_nombre', headerName: 'Tutor',minWidth: 10, flex: 1 },
        { field: 'grupo_descripcion', headerName: 'Grupo',minWidth: 10, flex: 1 },
        // { field: 'entregas_plantilla_descripcion', headerName: 'Plantilla',minWidth: 10, flex: 1 },
        { 
            field: 'es_alumno_fast', headerName: 'FAST',minWidth: 130,
            type: 'boolean',
            valueGetter: celda => {
                if(celda.value === '1') {
                    return true
                } else {
                    return false
                }
              },
            renderCell: (celda) => {
                if(celda.value === true) {
                    return <>SI</>
                } else {
                    return <>NO</>
                }
            }
        },
    ];

  const handleAsignarDestinos = () => {
    NotificacionAlumnoService.insertDestinos({ 
      usuario_id: usuarioSeleccionado.id, 
      notificacion_id: notificacionId, 
      destinos: selection 
    }).then( (response) => {
      handleCerrarDialogDestinos()
    })
  }

  useEffect(() => {
    if (apiRefDataGrid?.current.selectRows) {
      NotificacionAlumnoService.list_destinos({ usuario_id: usuarioSeleccionado.id, notificacion_id: notificacionId }).then( response => {
        if(response.destinos_ids) {
          setSelection(response.destinos_ids)
          apiRefDataGrid.current.selectRows(response.destinos_ids)
        }
      })
    }
  }, [apiRefDataGrid, alumnos]);

  return (
    <div>
      <Box>
        <div
          style={{
            height: 500,
            width: "100%",
            padding: "0 15px",
          }}
        >
          <DataGridPro
            apiRef={apiRefDataGrid}
            pagination={false}
            rows={alumnos}
            columns={columnas}
            rowHeight={30}
            loading={loading}
            checkboxSelection
            disableSelectionOnClick
            disableDensitySelector
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            // slots={{ toolbar: GridToolbar }}
            components={{Toolbar: GridToolbar}}
            onRowSelectionModelChange={(newSelection) => {
              setSelection(newSelection);
            }}
          />
        </div>
      </Box>
      <Stack spacing={2} direction="row" justifyContent={"space-between"}>
        <>&nbsp;</>
        <Stack spacing={2} direction="row">
          <Button onClick={() => handleCerrarDialogDestinos()}>Cancelar</Button>
          <Button onClick={() => handleAsignarDestinos()}>
            Asignar destinos
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

export default SeleccionarDestino
