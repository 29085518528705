import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import PlantillasPermisosDetalleContent from './components/PlantillasPermisosDetalleContent';

const PlantillasPermisosDetalle = () => {

    return (
        <>

            <TemplateDashboard>
                <PlantillasPermisosDetalleContent />
            </TemplateDashboard>

        </>
    )
};

export default PlantillasPermisosDetalle;