import DateFnsUtils from '@date-io/date-fns';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from 'date-fns/locale/es';

import { useFormik } from "formik";
import * as yup from 'yup';
import { getGrupos, grupoEntregaCreate } from '../../../../services/alumnos';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectUsuarioSeleccionado } from '../../../../redux/reducer/loginReducer';
// import AdjuntoEntrega from '../../../../components/AdjuntoEntrega/AdjuntoEntrega';
import { getListaDetalle } from '../../../../services/lista_detalle';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Swal from 'sweetalert2';

export const DialogEntregaForm = ({openModal, handleOpenModal}) => {
    const [tiposEntrega, setTiposEntrega] = useState([]);
    const [grupos, setGrupos] = useState([])
    const [gruposSeleccionados, setGruposSeleccionados] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {

        getGrupos({usuario_id: usuarioSeleccionado.id}).then((response) => {
            response && setGrupos(response.grupos)
          });

        getListaDetalle({usuario_id: usuarioSeleccionado.id, lista_id: '14'}).then( (response) => {
            setTiposEntrega(response.lista_detalle)
        })

        formik.setValues({
            abreviatura: "",
            descripcion: "",
            fecha_planificacion: null,
            fecha_prevista: null,
            cuerpo_mensaje: "",
            adjunto: "",
            adjunto_nombre_original: "",
            tipo_id: '139'
        })


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            abreviatura: "",
            descripcion: "",
            fecha_planificacion: null,
            fecha_prevista: null,
            cuerpo_mensaje: "",
            adjunto: "",
            adjunto_nombre_original: "",
            tipo_id: ""
        },
        validationSchema: yup.object({
            abreviatura: yup.string().required("Abreviatura es requerida"),
            descripcion: yup.string().required("Descripcion es requerida"),
            fecha_planificacion: yup.string().nullable().required("Fecha es requerida"),
            fecha_prevista: yup.string().nullable().required("Fecha es requerida"),
            cuerpo_mensaje: yup.string().required("Cuerpo del mensaje es requeridos"),
            tipo_id: yup.string().required("Tipo es requerido"),
          }),
        onSubmit: (values) => {

            handleOpenModal(false)

            Swal.fire({
                title: '¿Realmente deseas crear la entrega?',
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: `Cancelar`,
                confirmButtonColor: '#afcb0d',
                cancelButtonColor: '#a5a5a5',
                icon: 'warning',
                reverseButtons: true
              }).then((result) => {
    
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {

                    gruposSeleccionados.map( grupo_id => {

                        grupoEntregaCreate( { usuario_id: usuarioSeleccionado.id, grupo_id: grupo_id, campos: values }).then( (response) => {
                    
                            
                        })

                    })
                
                }
            })
            

            
        },
    });

    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <Dialog open={openModal} onClose={() => handleOpenModal(false)} maxWidth={'md'}>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

                <DialogTitle>Crear entrega</DialogTitle>
                <DialogContent style={{width: '700px'}}>


                    <Grid container>

                        <Grid item sm={12}>

                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
                            >
                                <InputLabel id="tipo_id-label">Tipo entrega</InputLabel>
                                <Select

                                    labelId="tipo_id-label"
                                    id="tipo_id"
                                    name="tipo_id"
                                    label="Tipo"
                                    fullWidth
                                    value={formik.values.tipo_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
                                >
                                    
                                    {        
                                        tiposEntrega && tiposEntrega.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.tipo_id && formik.errors.tipo_id}</FormHelperText>
                            </FormControl>

                        </Grid>

                        <Grid item sm={12}>
                            
                            <TextField
                                id="abreviatura"
                                name="abreviatura"
                                label="Abreviatura"
                                placeholder="Introduce la abreviatura"
                                fullWidth
                                margin="normal"
                                value={formik.values.abreviatura}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.abreviatura && Boolean(formik.errors.abreviatura)}
                                helperText={formik.touched.abreviatura && formik.errors.abreviatura}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>
                        
                        <Grid item sm={12}>
                            
                            <TextField
                                id="descripcion"
                                name="descripcion"
                                label="Descripcion"
                                placeholder="Introduce la descripcion"
                                fullWidth
                                margin="normal"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                                helperText={formik.touched.descripcion && formik.errors.descripcion}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item sm={6} style={{marginTop: 16, paddingRight: 10}}>
                            
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                
                                    fullWidth
                                    id="fecha_planificacion"
                                    label="Fecha Planificacion"
                                    placeholder='Introduce la fecha de planificacion'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_planificacion}
                                    onChange={value => formik.setFieldValue("fecha_planificacion", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_planificacion && Boolean(formik.errors.fecha_planificacion)}
                                    helperText={formik.touched.fecha_planificacion && formik.errors.fecha_planificacion}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>

                        <Grid item sm={6} style={{marginTop: 16}}>
                            
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                
                                    fullWidth
                                    id="fecha_prevista"
                                    label="Fecha Prevista"
                                    placeholder='Introduce la fecha prevista de entrega'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_prevista}
                                    onChange={value => formik.setFieldValue("fecha_prevista", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_prevista && Boolean(formik.errors.fecha_prevista)}
                                    helperText={formik.touched.fecha_prevista && formik.errors.fecha_prevista}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>

                        <Grid item sm={12}>
                            
                            <TextField
                                id="cuerpo_mensaje"
                                name="cuerpo_mensaje"
                                label="Explicación para el alumno"
                                placeholder="Introduce la explicación para el alumno"
                                fullWidth
                                multiline
                                minRows={5}
                                margin="normal"
                                value={formik.values.cuerpo_mensaje}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.cuerpo_mensaje && Boolean(formik.errors.cuerpo_mensaje)}
                                helperText={formik.touched.cuerpo_mensaje && formik.errors.cuerpo_mensaje}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item sm={12}>
                            <div style={{ height: 700, width: '100%', padding:"0 0" }}>
                                <DataGridPro
                                    rows={grupos}
                                    columns={[
                                        { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
                                        { field: 'descripcion', headerName: 'Descripción', flex: 1, minWidth: 250 },
                                    ]}
                                    checkboxSelection
                                    onRowSelectionModelChange={ (seleccion) => {
                                        setGruposSeleccionados(seleccion)
                                    }}
                                />
                            </div>
                        </Grid>
                        {/* <Grid item sm={12}>
                            <AdjuntoEntrega entregaId={entrega?.id} adjunto={formik.values.adjunto} adjunto_nombre_original={formik.values.adjunto_nombre_original} setFieldValue={formik.setFieldValue}/>
                        </Grid> */}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleOpenModal(false)}>Cancelar</Button>
                    <Button type='submit'>Crear entrega</Button>
                </DialogActions>
            </form>

        </Dialog>
    )
}