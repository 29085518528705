import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import * as yup from "yup";
import { useFormik } from "formik";
import { getListaDetalle } from "../../../services/lista_detalle";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { actualizar_documento_creadores, insertar_documento_creadores } from "../../../services/alumnos";

export const DialogDocumentoForm = ({
  openModal,
  setOpenModal,
  handleCloseDialog,
  documentoSeleccionado
}) => {
  const [tiposDocumentos, setTiposDocumentos] = useState("");

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  // const handleCreateGrupo = () => {
  // //   // setErrorDescripcion(false)
  // //   // if( descripcion === '' ) {
  // //   //   setErrorDescripcion(true)
  // //   //   return null;
  // //   // }
  // //   // grupoCreate({
  // //   //   usuario_id: usuarioSeleccionado.id,
  // //   //   descripcion: descripcion
  // //   // }).then((response) => {
  // //   //   cargarDocumentos()
  // //   //   setDescripcion( '' )
  // //   // });
  //   setOpenModal(false)
  // };

  /* -------------------------------------------------------------------------- */
  /*                            useEffect para update                           */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getListaDetalle({
      usuario_id: usuarioSeleccionado.id,
      lista_id: "26",
    }).then((response) => {
      setTiposDocumentos(response.lista_detalle);
    });
  }, []);

  useEffect(() => {

    formik.setValues({
      nombre: "",
      url: "",
      fecha_limite: null,
      descripcion: "",
      tipo_id: "",
    })

    if(documentoSeleccionado) {
      formik.setValues({
        nombre: documentoSeleccionado.nombre,
        url: documentoSeleccionado.url,
        fecha_limite: documentoSeleccionado.fecha_limite,
        descripcion: documentoSeleccionado.descripcion,
        tipo_id: documentoSeleccionado.tipo_id,
      })

    }
  
  }, [documentoSeleccionado])

  const formik = useFormik({
    initialValues: {
      nombre: "",
      url: "",
      fecha_limite: null,
      descripcion: "",
      tipo_id: "",
    },
    validationSchema: yup.object({
      nombre: yup.string().required("Obligatorio"),
      url: yup.string().required("Obligatorio"),
      fecha_limite: yup.string().nullable().required("Obligatorio"),
      descripcion: yup.string().required("Obligatorio"),
      tipo_id: yup.string().required("Obligatorio"),
    }),
    onSubmit: (values) => {

      if(documentoSeleccionado) {
        actualizar_documento_creadores({
          documento_id: documentoSeleccionado.id,
          usuario_id: usuarioSeleccionado.id,
          nombre: values.nombre,
          url: values.url,
          fecha_limite: values.fecha_limite,
          descripcion: values.descripcion,
          tipo_id: values.tipo_id,
      }).then( (response) => {
          handleCloseDialog()
      })
      } else {
          insertar_documento_creadores({
              usuario_id: usuarioSeleccionado.id,
              nombre: values.nombre,
              url: values.url,
              fecha_limite: values.fecha_limite,
              descripcion: values.descripcion,
              tipo_id: values.tipo_id,
          }).then( (response) => {
              handleCloseDialog()
          })
      }
    },
  });

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      maxWidth={"md"}
    >
      <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

        <DialogTitle>{documentoSeleccionado ? "Actualizar documento" : "Crear documento"}</DialogTitle>
        <DialogContent style={{ width: "700px" }}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: 16 }}
                error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
              >
                <InputLabel id="tipo_id-label">Tipo de documento</InputLabel>
                <Select
                  labelId="tipo_id-label"
                  id="tipo_id"
                  name="tipo_id"
                  label="Tipo de documento"
                  fullWidth
                  value={formik.values.tipo_id}
                  onChange={formik.handleChange}
                  error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
                >
                  {tiposDocumentos &&
                    tiposDocumentos.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.tipo_id && formik.errors.tipo_id}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item sm={6} style={{marginTop: 16, paddingRight: 10}}>
                              
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                    
                        fullWidth
                        id="fecha_limite"
                        label="Fecha Límite"
                        placeholder='Introduce la fecha límite'
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={formik.values.fecha_limite}
                        onChange={value => formik.setFieldValue("fecha_limite", value)}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        error={formik.touched.fecha_limite && Boolean(formik.errors.fecha_limite)}
                        helperText={formik.touched.fecha_limite && formik.errors.fecha_limite}
                    />
                </MuiPickersUtilsProvider>

            </Grid>
            
            <Grid item sm={12}>
              <TextField
                id="nombre"
                name="nombre"
                label="Nombre"
                placeholder="Introduce el nombre"
                fullWidth
                margin="normal"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="url"
                name="url"
                label="Url"
                placeholder="Introduce la URL"
                fullWidth
                margin="normal"
                value={formik.values.url}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="descripcion"
                name="descripcion"
                label="Descripcion"
                placeholder="Introduce la descripcion"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.descripcion && Boolean(formik.errors.descripcion)
                }
                helperText={formik.touched.descripcion && formik.errors.descripcion}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
          <Button type="submit">{ documentoSeleccionado ? "Actualizar documento" : "Crear documento"}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
