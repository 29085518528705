import axios from "axios";
import { BASE_URL_SERVICE } from "../constants";

export class PlantillasPermisosService {
    
    static list = async ( {usuario_id} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_list",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    
    static item = async ( {usuario_id, plantilla_permisos_id} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("plantilla_permisos_id", `${plantilla_permisos_id}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_item",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    static update = async ( {usuario_id, plantilla_permisos_id,nombre} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("plantilla_permisos_id", `${plantilla_permisos_id}`);
        formData.append("nombre", `${nombre}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_update",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    static lineasList = async ( {usuario_id, plantilla_permisos_id} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("plantilla_permisos_id", `${plantilla_permisos_id}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_lineas_list",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    static permisoDelete = async ( {usuario_id, permiso_id} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("permiso_id", `${permiso_id}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_lineas_delete",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    static plantillaPermisosDelete = async ( {usuario_id, plantilla_permisos_id} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("plantilla_permisos_id", `${plantilla_permisos_id}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_delete",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    static regenerarGeneral = async ( {usuario_id} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantilla_permisos_general_regenerar",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    static permisosList = async ( {usuario_id,tipo} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("tipo", `${tipo}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/portal_alumno_permisos_list",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    static permisoAdd = async ( {usuario_id,plantilla_permisos_id, permiso} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("plantilla_permisos_id", `${plantilla_permisos_id}`);
        formData.append("permiso", `${permiso}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_lineas_add",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    
    static insert = async ( {usuario_id, campos} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("nombre", `${campos.nombre}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/plantillas_permisos_create",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    
    static alumnoAsignarPlantillaPermisos = async ( {usuario_id, alumno_id, plantilla_permisos_id} ) => {

      try {
        let formData = new FormData();

        formData.append("usuario_id", `${usuario_id}`);
        formData.append("alumno_id", `${alumno_id}`);
        formData.append("plantilla_permisos_id", `${plantilla_permisos_id}`);

        const response = await axios({
          method: "post",
          url: BASE_URL_SERVICE + "/alumno/alumno_asignar_plantilla_permisos",
          data: formData,
          headers: {
             'Content-Type': 'multipart/form-data',
          },
        });

        return response.data;
      } catch (error) {
          return undefined;
      }

    }
    
}