import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import GestionDocumentosCreadoresContent from './components/GestionDocumentosCreadoresContent';

const GestionDocumentosCreadores = () => {

    return (
        <>

            <TemplateDashboard>
                <GestionDocumentosCreadoresContent />
            </TemplateDashboard>

        </>
    )
};

export default GestionDocumentosCreadores;