import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import GruposContent from './components/GruposContent';

const Grupos = () => {

    return (
        <>

            <TemplateDashboard>
                <GruposContent />
            </TemplateDashboard>

        </>
    )
};

export default Grupos;