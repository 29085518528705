import DateFnsUtils from "@date-io/date-fns";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

import { useFormik } from "formik";
import * as yup from "yup";
import {
  getGruposItem,
  grupoEntregaCreate,
  grupoTutoresInsert,
} from "../../../../services/alumnos";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
import { useEffect, useState } from "react";
import { getListaDetalle } from "../../../../services/lista_detalle";

export const DialogTutorForm = ({
  openModal,
  handleOpenModal,
  grupoId,
  tutores,
}) => {
  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
    // getListaDetalle({usuario_id: usuarioSeleccionado.id, lista_id: '14'}).then( (response) => {
    //     setTiposEntrega(response.lista_detalle)
    // })
    // if(entrega) {
    //     formik.setValues({
    //         abreviatura: entrega.abreviatura,
    //         descripcion: entrega.descripcion,
    //         fecha_planificacion: entrega.fecha_planificacion,
    //         fecha_prevista: entrega.fecha_prevista,
    //         cuerpo_mensaje: entrega.cuerpo_mensaje,
    //         adjunto: entrega.adjunto,
    //         adjunto_nombre_original: entrega.adjunto_nombre_original,
    //         tipo_id: entrega.tipo_id
    //     })
    // } else {
    //     formik.setValues({
    //         abreviatura: "",
    //         descripcion: "",
    //         fecha_planificacion: null,
    //         fecha_prevista: null,
    //         cuerpo_mensaje: "",
    //         adjunto: "",
    //         adjunto_nombre_original: "",
    //         tipo_id: "139"
    //     })
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                   Formik                                   */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      tutor_id: "",
      funcion_tutor: "",
    },
    validationSchema: yup.object({
      tutor_id: yup.string().required("Tutor requerido"),
    }),
    onSubmit: (values) => {

      grupoTutoresInsert({
        usuario_id: usuarioSeleccionado.id,
        grupo_id: grupoId,
        tutor_id: values.tutor_id,
        funcion_tutor: values.funcion_tutor
      }).then((response) => {
        handleOpenModal();
      });

    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Dialog
      open={openModal}
      onClose={() => handleOpenModal(false)}
      maxWidth={"md"}
    >
      <form
        noValidate
        method="POST"
        enctype="multipart/form-data"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <DialogTitle>Nuevo tutor</DialogTitle>
        <DialogContent style={{ width: "700px" }}>
          <Grid container>
            <Grid item sm={12}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: 16 }}
                error={
                  formik.touched.tutor_id && Boolean(formik.errors.tutor_id)
                }
              >
                <InputLabel id="tutor_id-label">Tutor</InputLabel>
                <Select
                  labelId="tutor_id-label"
                  id="tutor_id"
                  name="tutor_id"
                  label="Tutor"
                  fullWidth
                  value={formik.values.tutor_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.tutor_id && Boolean(formik.errors.tutor_id)
                  }
                >
                  {tutores &&
                    tutores.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre} {item.apellidos}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.tipo_id && formik.errors.tipo_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={12} style={{ marginTop: 20 }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label" style={{marginBottom: 20}}>
                  Función que realiza este tutor
                </FormLabel>
                <RadioGroup defaultValue="female" name="funcion_tutor">
                  <FormControlLabel
                    value="tutor"
                    control={<Radio onChange={ (item) => {
                        formik.setFieldValue('funcion_tutor', 'tutor')
                    }}/>}
                    label="Tutor"
                  />
                  <FormControlLabel
                    value="coordinador"
                    control={<Radio onChange={ (item) => {
                        formik.setFieldValue('funcion_tutor', 'coordinador')
                    }}/>}
                    label="Coordinador"
                  />
                  <FormControlLabel
                    value="apoyo"
                    control={<Radio onChange={ (item) => {
                        formik.setFieldValue('funcion_tutor', 'apoyo')
                    }}/>}
                    label="Apoyo"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenModal()}>Cancelar</Button>
          <Button type="submit">Asignar tutor</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
