import DateFnsUtils from '@date-io/date-fns';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from 'date-fns/locale/es';

import { useFormik } from "formik";
import * as yup from 'yup';
import { getGruposItem, grupoEntregaCreate, grupoEntregaUpdate } from '../../../../services/alumnos';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../../redux/reducer/loginReducer';
import { useEffect, useState } from 'react';
import { getListaDetalle } from '../../../../services/lista_detalle';


export const DialogEntregaForm = ({openModal, handleOpenModal, grupoId, setEntregas, entrega}) => {
    const [tiposEntrega, setTiposEntrega] = useState([]);

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    
    useEffect(() => {
      
        getListaDetalle({usuario_id: usuarioSeleccionado.id, lista_id: '14'}).then( (response) => {
            setTiposEntrega(response.lista_detalle)
        })

        if(entrega) {
            formik.setValues({
                abreviatura: entrega.abreviatura,
                descripcion: entrega.descripcion,
                fecha_planificacion: entrega.fecha_planificacion,
                fecha_prevista: entrega.fecha_prevista,
                cuerpo_mensaje: entrega.cuerpo_mensaje,
                adjunto: entrega.adjunto,
                adjunto_nombre_original: entrega.adjunto_nombre_original,
                tipo_id: entrega.tipo_id
            })
        } else {
            formik.setValues({
                abreviatura: "",
                descripcion: "",
                fecha_planificacion: null,
                fecha_prevista: null,
                cuerpo_mensaje: "",
                adjunto: "",
                adjunto_nombre_original: "",
                tipo_id: "139"
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entrega])
    
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            abreviatura: "",
            descripcion: "",
            fecha_planificacion: null,
            fecha_prevista: null,
            cuerpo_mensaje: "",
            adjunto: "",
            adjunto_nombre_original: "",
            tipo_id: "139",
        },
        validationSchema: yup.object({
            abreviatura: yup.string().required("Abreviatura es requerida"),
            descripcion: yup.string().required("Descripcion es requerida"),
            fecha_planificacion: yup.string().nullable().required("Fecha es requerida"),
            fecha_prevista: yup.string().nullable().required("Fecha es requerida"),
            cuerpo_mensaje: yup.string().required("Cuerpo del mensaje es requeridos"),
            tipo_id: yup.string().required("Tipo es requerido"),
          }),
        onSubmit: (values) => {

            if(entrega) {

                grupoEntregaUpdate( { usuario_id: usuarioSeleccionado.id, grupo_id: grupoId, entrega_id: entrega.id, campos: values }).then( (response) => {
                
                    getGruposItem({usuario_id: usuarioSeleccionado.id, grupo_id: grupoId}).then((response) => {
                
                        response && setEntregas(response.grupo.entregas)
                
                    });

                    handleOpenModal()
                    
                })

                // entregaPlantillaLineaUpdate( { usuario_id: usuarioSeleccionado.id, plantilla_id: plantillaId, entrega_id: entrega.id, campos: values } ).then( (response) => {

                //     // if(response.resultado_archivo.success === true) {
                //         // response.resultado_archivo.message
                //     // } else {
                        
                //     // }
                    
                //     getEntregasPlantillasByTutor({ tutor_id:'-1', plantilla_id: plantillaId }).then((response) => {
                
                //         response && setPlantilla(response.plantilla)
                
                //     });

                //     handleOpenModal()
                    
                // })

            } else {

                grupoEntregaCreate( { usuario_id: usuarioSeleccionado.id, grupo_id: grupoId, campos: values }).then( (response) => {
                
                    getGruposItem({usuario_id: usuarioSeleccionado.id, grupo_id: grupoId}).then((response) => {
                
                        response && setEntregas(response.grupo.entregas)
                
                    });

                    handleOpenModal()
                    
                })
                
            }

            

            
        },
    });

    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <Dialog open={openModal} onClose={() => handleOpenModal(false)} maxWidth={'md'}>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

                <DialogTitle>{entrega ? 'Actualizar entrega' : 'Crear entrega'}</DialogTitle>
                <DialogContent style={{width: '700px'}}>


                    <Grid container>

                        <Grid item sm={12}>

                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
                            >
                                <InputLabel id="tipo_id-label">Tipo entrega</InputLabel>
                                <Select

                                    labelId="tipo_id-label"
                                    id="tipo_id"
                                    name="tipo_id"
                                    label="Tipo"
                                    fullWidth
                                    value={formik.values.tipo_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
                                >
                                    
                                    {        
                                        tiposEntrega && tiposEntrega.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.tipo_id && formik.errors.tipo_id}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item sm={12}>
                            
                            <TextField
                                id="abreviatura"
                                name="abreviatura"
                                label="Abreviatura"
                                placeholder="Introduce la abreviatura"
                                fullWidth
                                margin="normal"
                                value={formik.values.abreviatura}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.abreviatura && Boolean(formik.errors.abreviatura)}
                                helperText={formik.touched.abreviatura && formik.errors.abreviatura}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>
                        
                        <Grid item sm={12}>
                            
                            <TextField
                                id="descripcion"
                                name="descripcion"
                                label="Descripcion"
                                placeholder="Introduce la descripcion"
                                fullWidth
                                margin="normal"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                                helperText={formik.touched.descripcion && formik.errors.descripcion}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item sm={6} style={{marginTop: 16, paddingRight: 10}}>
                            
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                
                                    fullWidth
                                    id="fecha_planificacion"
                                    label="Fecha Planificacion"
                                    placeholder='Introduce la fecha de planificacion'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_planificacion}
                                    onChange={value => formik.setFieldValue("fecha_planificacion", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_planificacion && Boolean(formik.errors.fecha_planificacion)}
                                    helperText={formik.touched.fecha_planificacion && formik.errors.fecha_planificacion}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid item sm={6} style={{marginTop: 16}}>
                            
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                
                                    fullWidth
                                    id="fecha_prevista"
                                    label="Fecha Prevista"
                                    placeholder='Introduce la fecha prevista de entrega'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_prevista}
                                    onChange={value => formik.setFieldValue("fecha_prevista", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_prevista && Boolean(formik.errors.fecha_prevista)}
                                    helperText={formik.touched.fecha_prevista && formik.errors.fecha_prevista}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>

                        <Grid item sm={12}>
                            
                            <TextField
                                id="cuerpo_mensaje"
                                name="cuerpo_mensaje"
                                label="Explicación para el alumno"
                                placeholder="Introduce la explicación para el alumno"
                                fullWidth
                                multiline
                                minRows={5}
                                margin="normal"
                                value={formik.values.cuerpo_mensaje}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.cuerpo_mensaje && Boolean(formik.errors.cuerpo_mensaje)}
                                helperText={formik.touched.cuerpo_mensaje && formik.errors.cuerpo_mensaje}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        {/* <Grid item sm={12}>
                            <AdjuntoEntregaPlantilla plantilla_linea_id={entrega?.id} adjunto={formik.values.adjunto} adjunto_nombre_original={formik.values.adjunto_nombre_original} setFieldValue={formik.setFieldValue} />
                        </Grid> */}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleOpenModal()}>Cancelar</Button>
                    <Button type='submit'> { entrega ? 'Actualizar entrega' : 'Crear entrega' }</Button>
                </DialogActions>
            </form>

        </Dialog>
    )
}