import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import DocumentosContent from './components/DocumentosContent';

const GestionDocumentos = () => {

    return (
        <>

            <TemplateDashboard>
                <DocumentosContent />
            </TemplateDashboard>

        </>
    )
};

export default GestionDocumentos;