import { Chip } from '@material-ui/core'
import { amber, blue, green, grey, orange, purple, red, yellow } from '@material-ui/core/colors'
import React from 'react'

const StatusTicket = ({ticketStatus, esRetrasado = '0', tipo}) => {
    // tipo = Duda | Entrega | Simulacro
    return (

        <>
        {tipo === 'Entrega' &&
            <>
                {ticketStatus === 'Nuevo' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Abierto' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Abierto\n' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Pendiente' && esRetrasado === '1' && <Chip size="small" style={{backgroundColor: amber[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Pendiente' && esRetrasado === '0' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Ignorado' && <Chip size="small" style={{backgroundColor: purple[500], color: 'white'}} label={ticketStatus} />}
                {(ticketStatus === 'Resuelto' || ticketStatus === 'Closed') && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={'Resuelto'} />}
                {ticketStatus === '' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={'Pendiente'} />}
            </>}

        {tipo === 'Simulacro' &&
            <>
                {ticketStatus === 'Nuevo' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Abierto' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Abierto\n' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Pendiente' && esRetrasado === '1' && <Chip size="small" style={{backgroundColor: amber[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Pendiente' && esRetrasado === '0' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Ignorado' && <Chip size="small" style={{backgroundColor: purple[500], color: 'white'}} label={ticketStatus} />}
                {(ticketStatus === 'Resuelto' || ticketStatus === 'Closed') && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={'Resuelto'} />}
                {ticketStatus === '' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={'Pendiente'} />}
            </>}
        
        {tipo === 'Duda' &&
            <>
                {ticketStatus === 'Nuevo' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Abierto' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Abierto\n' && <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Pendiente' && esRetrasado === '1' && <Chip size="small" style={{backgroundColor: amber[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Pendiente' && esRetrasado === '0' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={ticketStatus} />}
                {ticketStatus === 'Ignorado' && <Chip size="small" style={{backgroundColor: purple[500], color: 'white'}} label={ticketStatus} />}
                {(ticketStatus === 'Resuelto' || ticketStatus === 'Closed') && <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={'Resuelto'} />}
                {ticketStatus === '' && <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={'Pendiente'} />}
            </>}
        </>
    )
  
}

export default StatusTicket
