import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../components/Loading";

import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";

import { Box, Button, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
// import { GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
// import Swal from "sweetalert2";
// import { useHistory } from "react-router-dom";
// import { getEntregasPlantillasByTutor } from "../../../../services/alumnos";
// import { Stack } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DataGridInnovattio from "../../../components/DataGridInnovatiio";
import QuickSearchToolbar from "../../../components/QuickSearchToolbar";
import { requestSearch } from "../../../utils/regExp";
import { DialogPlantillaPermisosForm } from "./DialogPlantillaPermisosForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PlantillasPermisosService } from "../../../services/PlantillasPermisos.service";
// import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Swal from "sweetalert2";


// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
//   loadingContainer: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     minHeight: "90vh",
//   },
//   emptyContainer: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     minHeight: "80vh",
//     width: "100%",
//     flexDirection: "column",
//     textAlign: "center",
//   },
//   emptyImage: {
//     width: "100%",
//   },

//   enlace: {
//     color: theme.palette.link.main,
//     textDecoration: "none",
//   },
}));

export default function PlantillasPermisosContent() {
  const classes = useStyles();

  const [plantillas, setPlantillas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  // const [plantillaSeleccionadaId, setPlantillaSeleccionadaId] = useState("");
  // const [openDialogoDifusion, setOpenDialogoDifusion] = useState(false);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  // const history = useHistory();

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando plantillas");

    cargarPlantillas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                              Cargar Plantillas                             */
  /* -------------------------------------------------------------------------- */
  const cargarPlantillas = () => {
    const usuario_id = usuarioSeleccionado.id;

    PlantillasPermisosService.list({usuario_id: usuario_id}).then((response) => {
        setLoading(false);

        response && setPlantillas(response.plantillas);
    });

  };

  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */

  const columnas = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 370 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Eliminar"
          onClick={(e) => {
            linkDelete(params.id);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<CreateIcon />}
          label="Cambiar nombre"
          onClick={(e) => {
            console.log("Entro")
            PlantillasPermisosService.item({
              usuario_id: usuarioSeleccionado.id,
              plantilla_permisos_id: params.id,
            }).then((response) => {
              const plantilla = response.plantilla;
              const nombre = plantilla.nombre;

              Swal.fire({
                title: "Cambiar nombre de la plantilla",
                input: "text",
                inputValue: nombre,
                showCancelButton: true,
                confirmButtonText: "Cambiar",
                cancelButtonText: `Cancelar`,
                confirmButtonColor: "#afcb0d",
                cancelButtonColor: "#a5a5a5",
                inputValidator: (value) => {
                  if (!value) {
                    return "¡Debes ingresar un nombre!";
                  }
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  PlantillasPermisosService.update({
                    usuario_id: usuarioSeleccionado.id,
                    plantilla_permisos_id: params.id,
                    nombre: result.value,
                  }).then((response) => {
                    cargarPlantillas();
                  });
                }
              });
            });
          }}
          showInMenu
        />,
      ],
    },
  ];

  const linkDelete = (id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar la plantilla?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#afcb0d",
      cancelButtonColor: "#a5a5a5",
      icon: "warning",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const usuario_id = usuarioSeleccionado.id;

        PlantillasPermisosService.plantillaPermisosDelete({
          usuario_id: usuario_id,
          plantilla_permisos_id: id,
        }).then((response) => {
          cargarPlantillas();
        });

      } else if (result.isDenied) {
        // //   Swal.fire('Changes are not saved', '', 'info')
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */

  const Contenido = () => {
    const [rows, setRows] = React.useState(plantillas);
    const [searchText, setSearchText] = React.useState("");
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const history = useHistory();

    const handleOpenModalCreate = (value) => {
      setOpenModalCreate(value);
      cargarPlantillas()
    }

    /* -------------------------------------------------------------------------- */
  /*                         handleAlumnoEntregaDetalle                         */
  /* -------------------------------------------------------------------------- */
  const handleAlumnoEntregaDetalle = (id) => {
    history.push("/plantillas_permisos/detalle/" + id);
  };

    return (
      <>
        <Stack direction={"row"} spacing={2}>
          <Box mt={0} mb={0} mx={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Plantillas de permisos
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenModalCreate(true)}
          >
            Añadir plantilla
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              PlantillasPermisosService.regenerarGeneral({usuario_id: usuarioSeleccionado.id})
              cargarPlantillas();
            }}
          >
            Regenerar plantilla general
          </Button>
        </Stack>

        
        <Box>
          <div style={{ height: 700, width: "100%", padding: "0 15px" }}>
            <DataGridInnovattio
              rows={rows}
              columns={columnas}
              rowHeight={30}
              components={{ Toolbar: QuickSearchToolbar }}
              onDoubleClick={(evento) => handleAlumnoEntregaDetalle(evento.id)}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) =>
                    requestSearch(
                      plantillas,
                      setSearchText,
                      setRows,
                      event.target.value
                    ),
                  clearSearch: () =>
                    requestSearch(plantillas, setSearchText, setRows, ""),
                },
              }}
            />

          </div>

          <DialogPlantillaPermisosForm
            openModal={openModalCreate}
            handleOpenModal={handleOpenModalCreate}
          />
        </Box>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
