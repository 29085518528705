import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import DataGridInnovattio from "../../../components/DataGridInnovatiio";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import { PlantillasPermisosService } from "../../../services/PlantillasPermisos.service";

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
}));

export default function PlantillasPermisosDetalleContent() {
  const { id: plantilla_permisos_id } = useParams();

  const classes = useStyles();

  const [plantillaPermisosLineas, setPlantillaPermisosLineas] =
    useState(undefined);
  const [plantillaPermisos, setPlantillaPermisos] = useState(undefined);
  const [permisos, setPermisos] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [permisoAddSelect, setPermisoAddSelect] = useState([]);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const history = useHistory();

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando permisos");

    cargarPermisos();
    cargarPlantillaPermisosLineas();
    cargarPlantillaPermisos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                              Cargar Plantillas                             */
  /* -------------------------------------------------------------------------- */
  const cargarPlantillaPermisosLineas = () => {
    const usuario_id = usuarioSeleccionado.id;

    PlantillasPermisosService.lineasList({
      usuario_id: usuario_id,
      plantilla_permisos_id: plantilla_permisos_id,
    }).then((response) => {
      setLoading(false);
      setPlantillaPermisosLineas(response.lineas);
    });
  };

  const cargarPlantillaPermisos = () => {
    const usuario_id = usuarioSeleccionado.id;

    PlantillasPermisosService.item({
      usuario_id: usuario_id,
      plantilla_permisos_id: plantilla_permisos_id,
    }).then((response) => {
      setLoading(false);
      setPlantillaPermisos(response.plantilla);
    });
  };

  const cargarPermisos = () => {
    const usuario_id = usuarioSeleccionado.id;

    PlantillasPermisosService.permisosList({
      usuario_id: usuario_id,
      tipo: "portal_alumno",
    }).then((response) => {
      setLoading(false);
      setPermisos(response.permisos);
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               handleOnSubmit                               */
  /* -------------------------------------------------------------------------- */

  const columnas = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "permiso", headerName: "Permiso", minWidth: 50, flex: 1 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Eliminar"
          onClick={(e) => {
            // console.log(params)
            linkDelete(params.id);
          }}
          showInMenu
        />,
      ],
    },
  ];

  const linkDelete = (id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar el permiso?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#afcb0d",
      cancelButtonColor: "#a5a5a5",
      icon: "warning",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const usuario_id = usuarioSeleccionado.id;

        PlantillasPermisosService.permisoDelete({
          usuario_id: usuario_id,
          permiso_id: id,
        }).then((response) => {
          cargarPlantillaPermisosLineas();
        });
      } else if (result.isDenied) {
        // //   Swal.fire('Changes are not saved', '', 'info')
      }
    });
  };

  const ListadoPermisos = () => {
    return (
      // <Box sx={{ marginX: 2, marginY: 0, marginBottom: 20 }}>
      // <Paper style={{ padding: 20 }}>
      <Box mb={2}>
        <Box mb={2} style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            style={{ fontWeight: 500 }}
          >
            Permisos plantilla: {plantillaPermisos && plantillaPermisos.nombre}
          </Typography>

          <Divider />
          <div style={{display:"flex"}}>
            <FormControl fullWidth>
              <Select
                labelId="permiso-label"
                id="permiso"
                name="permiso"
                label="Permiso"
                fullWidth
                variant="standard"
                value={permisoAddSelect}
                onChange={(e) => setPermisoAddSelect(e.target.value)}
                placeholder="Selecciona un permiso"
              >
                {permisos &&
                  permisos.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.permiso}>
                        {item.permiso}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <Button style={{width: 250}} onClick={() => {
              console.log(permisoAddSelect)
              const usuario_id = usuarioSeleccionado.id;

              PlantillasPermisosService.permisoAdd({
                usuario_id: usuario_id,
                plantilla_permisos_id: plantilla_permisos_id,
                permiso: permisoAddSelect,
              }).then((response) => {
                cargarPlantillaPermisosLineas();
              });
            }}>Añadir permiso</Button>
          </div>
        </Box>

        {plantillaPermisosLineas && (
          <div style={{ display: "flex", height: 700 }}>
            <DataGridPro
              pagination={false}
              rows={plantillaPermisosLineas}
              columns={columnas}
              rowHeight={30}
              onRowDoubleClick={(row) => {
                // setEntregaDoubleClick(row.row);
                // setOpenDialogEntregaForm(true);
              }}
            />
          </div>
        )}
      </Box>
      // </Paper>
      // </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */
  const Contenido = () => {
    const [tabSeleccionada, setTabSeleccionada] = React.useState(0);

    const handleChangeTabSeleccionada = (event, newValue) => {
      setTabSeleccionada(newValue);
    };

    return (
      <>
        <Paper style={{ margin: 10, padding: 20 }}>
          <ListadoPermisos />
        </Paper>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
