import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import Moment from "react-moment";
import { eliminarDocumentoDreador, getDocumentoCreadoresList, getGruposItem, grupoEntregaDelete, grupoTutoresDelete, grupoUpdate } from "../../../services/alumnos";
import { DialogCreadorForm, DialogTutorForm } from "./DialogCreadorForm";
import { getTutores } from "../../../services/tutores";
import { IconButton, Stack } from "@mui/material";
import DataGridInnovattio from "../../../components/DataGridInnovatiio";
import QuickSearchToolbar from "../../../components/QuickSearchToolbar";
import { blue, green, grey, red } from "@material-ui/core/colors";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
}));

/* -------------------------- GruposDetalleContent -------------------------- */
export default function GestionDocumentosCreadoresContent() {
  const { id: documento_id } = useParams();

  const classes = useStyles();

  // const [grupo, setGrupo] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [creadorSeleccionado, setCreadorSeleccionado] = useState(null)

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando datos");

    cargarCreadores();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documento_id]);

  /* ------------------------------- cargarGrupo ------------------------------ */
  const cargarCreadores = () => {
    setLoading(false)

    getDocumentoCreadoresList({usuario_id: usuarioSeleccionado.id, documento_id: documento_id}).then( response => {
      setDocumentos(response.documentos)
    })

  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */

  const Contenido = () => {
    const [rows, setRows] = React.useState(documentos);
    const history = useHistory()

    const columnas = [
      { field: "id", hide: true, headerName: "ID", minWidth: 10 },
      { field: "creador_nombre", headerName: "Creador", flex: 1, minWidth: 350 },
      { field: "funcion_nombre", headerName: "Funcion", minWidth: 150 },
      { field: "estado_nombre", headerName: "Estado", minWidth: 150, renderCell: (cellValues) => {
        return (
          <>
          { cellValues.row.estado === "241" && <Chip label={cellValues.value } size="small" style={{backgroundColor: red[400], color: 'white'}}></Chip>} {/* Pendiente */ }
          { cellValues.row.estado === "242" && <Chip label={cellValues.value } size="small" style={{backgroundColor: blue[400], color: 'white'}}></Chip>} {/* En proceso */}
          { cellValues.row.estado === "243" && <Chip label={cellValues.value } size="small" style={{backgroundColor: green[400], color: 'white'}}></Chip>} {/* Terminado */}
          </>
        )
      } },
      { field: "fecha_limite", headerName: "Fecha", minWidth: 160 },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            label="Eliminar"
            onClick={ () => { 
              Swal.fire({
                title: '¿Realmente deseas eliminar este creador?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: `Cancelar`,
                confirmButtonColor: '#afcb0d',
                cancelButtonColor: '#a5a5a5',
                icon: 'warning',
                reverseButtons: true
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  eliminarDocumentoDreador({usuario_id: usuarioSeleccionado.id, documento_creador_id: params.id}).then( response => {
                    cargarCreadores()
                  })
                } else if (result.isDenied) {
                //   Swal.fire('Changes are not saved', '', 'info')
                }
              })

            }}
            showInMenu
          />,
        ],
      }
    ];

    const handleCloseDialog = () => {
      setOpenModal(false);
      setCreadorSeleccionado(null)
      cargarCreadores();
    }

    return (
      <>
        <Stack direction={"row"} spacing={2} sx={{alignItems: "center"}}>
          <Box mt={0} mb={0} mx={2}>
            <Stack direction={"row"} sx={{alignItems: "center"}}>
              
              <IconButton>
                <ArrowBackIosIcon onClick={ () => { history.go(-1)}} />
              </IconButton>

              <Typography
                variant="h5"
                component="h3"
                gutterBottom
                style={{ fontWeight: 500, margin: 0 }}
              >
                Gestión de documentos - Creadores
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            Añadir creador
          </Button>
        </Stack>

        
        <Box>
          <div style={{ height: 700, width: "100%", padding: "0 15px" }}>
            <DataGridInnovattio
              rows={rows}
              columns={columnas}
              rowHeight={30}
              onDoubleClick={(item) => {
                setCreadorSeleccionado(item);
                setOpenModal(true)
              }}
            />
            
            <DialogCreadorForm
              documento_id={documento_id}
              openModal={openModal}
              creador={creadorSeleccionado}
              handleOpenModal={handleCloseDialog}
            /> 

          </div>
        </Box>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}

//#region COMPONENTES



//#endregion