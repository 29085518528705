import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DialogEntregaForm } from "./DialogEntregaForm";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import Moment from "react-moment";
import { getGruposItem, grupoEntregaDelete, grupoTutoresDelete, grupoUpdate, grupoUpdateMensajeBienvenida } from "../../../../services/alumnos";
import { DialogTutorForm } from "./DialogTutorForm";
import { getTutores } from "../../../../services/tutores";
import { RichTextField } from "../../../../components/RichTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  // loadingContainer: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   minHeight: "90vh",
  // },
  // emptyContainer: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   minHeight: "80vh",
  //   width: "100%",
  //   flexDirection: "column",
  //   textAlign: "center",
  // },
  // emptyImage: {
  //   width: "100%",
  // },

  // enlace: {
  //   color: theme.palette.link.main,
  //   textDecoration: "none",
  // },
}));

/* -------------------------- GruposDetalleContent -------------------------- */
export default function GruposDetalleContent() {
  const { id: grupo_id } = useParams();

  const classes = useStyles();

  const [grupo, setGrupo] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando datos");

    cargarGrupo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grupo_id]);

  /* ------------------------------- cargarGrupo ------------------------------ */
  const cargarGrupo = () => {

    getGruposItem({
      usuario_id: usuarioSeleccionado.id,
      grupo_id: grupo_id,
    }).then((response) => {
      setLoading(false);

      response && setGrupo(response.grupo);

    });

  };

  useEffect(() => {
    console.log(grupo)
  }, [grupo]);

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const schema = yup.object().shape({
    comentario: yup.string().required("Campo obligatorio"),
  });

  const Contenido = () => {
    const [tabSeleccionada, setTabSeleccionada] = React.useState(0);

    const handleChangeTabSeleccionada = (event, newValue) => {
      setTabSeleccionada(newValue);
    };
    const {
      handleSubmit,
      formState: { errors },
      getValues,
      setValue
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = (data) => {

      grupoUpdateMensajeBienvenida({ usuario_id: usuarioSeleccionado.id, grupo_id: grupo_id, mensaje_bienvenida: data.comentario }).then((response) => {
        console.log(response)
      });

    };
    useEffect(() => {
      setValue('comentario', grupo?.mensaje_bienvenida)
      console.log(grupo)
    }, [grupo]);

    return (
      <>
        { grupo && <FormPlantilla grupo={grupo} />}

        <Box sx={{ marginX: 2, marginY: 0, marginBottom: 20 }}>
          <Paper style={{ padding: 20 }}>
            <AppBar position="static">
            <Tabs
              value={tabSeleccionada}
              onChange={handleChangeTabSeleccionada}
              indicatorColor="secondary"
              textColor="inherit"
              variant="standard"
              aria-label="full width tabs example"
            >
              <Tab label="Entregas" {...a11yProps(0)} />
              <Tab label="Tutores" {...a11yProps(1)} />
              <Tab label="Mensaje de bienvenida" {...a11yProps(2)} />
            </Tabs>
            </AppBar>
            <TabPanel value={tabSeleccionada} index={0}>
              { grupo && <ListadoEntregas 
                usuario_id={usuarioSeleccionado.id}
                grupo={grupo} 
              />}
            </TabPanel>
            <TabPanel value={tabSeleccionada} index={1}>
              { grupo && <ListadoTutores 
                usuario_id={usuarioSeleccionado.id}
                grupo={grupo} 
              />}
            </TabPanel>
            <TabPanel value={tabSeleccionada} index={2}>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <RichTextField getValues={getValues} setValue={setValue} errors={errors} />
                <Button type="submit" variant="contained" color="primary">Guardar mensaje</Button>
              </form>
            </TabPanel>
          </Paper>
        </Box>
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido grupo={grupo} />
      )}
    </div>
  );
}

//#region COMPONENTES

/* -------------------------------------------------------------------------- */
/*                                FormPlantilla                               */
/* -------------------------------------------------------------------------- */
const FormPlantilla = ({grupo}) => {
  const classes = useStyles();

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const history = useHistory();

  const [errorDescripcion, setErrorDescripcion] = useState(false);

  useEffect(() => {
    console.log(grupo)
    formik.setValues({
      descripcion: grupo?.descripcion,
    });
  }, [grupo]);

  const formik = useFormik({
    initialValues: {
      descripcion: "",
    },
    validationSchema: yup.object({
      descripcion: yup.string().required("Descripcion es requerida"),
    }),
    onSubmit: (values) => {
      grupoUpdate({
        usuario_id: usuarioSeleccionado.id,
        grupo_id: grupo.id,
        descripcion: values.descripcion,
      }).then((response) => {
        // console.log(response)
        // getEntregasPlantillasByTutor({ tutor_id:'-1', plantilla_id: plantilla.id }).then((response) => {
        //     response && setPlantilla(response.plantilla)
        // });
      });
    },
  });

  return (
    <Box sx={{ marginX: 2, marginTop: 12, marginBottom: 10 }}>
      <Paper style={{ padding: 20 }}>
        <Box mb={2}>
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            style={{ fontWeight: 500 }}
          >
            Grupo
          </Typography>
          <Divider />
        </Box>

        <form
          className={classes.formulario}
          noValidate
          method="POST"
          enctype="multipart/form-data"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Box mt={5}>
            <FormControl
              FormControl
              variant="outlined"
              fullWidth
              error={errorDescripcion}
            >
              <TextField
                id="descripcion"
                name="descripcion"
                label="Descripcion"
                placeholder="Introduce la descripcion"
                fullWidth
                margin="normal"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                variant="outlined"
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {errorDescripcion && (
                <FormHelperText>
                  Es necesario escribir una descripción de la plantilla
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          {/* Botones de guardado del formulario*/}
          <Box mt={5}>
            <Divider />
            <Box
              mt={3}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                color="primary"
                style={{ marginRight: "30px" }}
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>

              <Button variant="contained" color="primary" type="submit">
                Guardar cambios
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

/* -------------------------------------------------------------------------- */
/*                               ListadoTutores                               */
/* -------------------------------------------------------------------------- */
const ListadoTutores = ({usuario_id, grupo}) => {
  const [openDialogTutorForm, setOpenDialogTutorForm] = useState(false);
  const [tutores, setTutores] = useState([])
  const [tutoresGrupo, setTutoresGrupo] = useState(grupo.tutores)

  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {

    getTutores({usuario_id: usuario_id}).then( response => {
      setTutores(response.tutores)
    })

  }, [])
  
  /* ----------------------------- columnasTutores ---------------------------- */
  const columnasTutores = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "tutor_usuario_nombre", headerName: "Tutor", minWidth: 50, flex: 1 },
    { field: "es_tutor", headerName: "Tutor", minWidth: 50, flex: 1, renderCell: (item) => <>{item.row.es_tutor==='1' ? 'SI' : ''}</> },
    { field: "es_coordinador", headerName: "Coordinador", minWidth: 50, flex: 1 , renderCell: (item) => <>{item.row.es_coordinador==='1' ? 'SI' : ''}</> },
    { field: "es_apoyo", headerName: "Apoyo", minWidth: 50, flex: 1 , renderCell: (item) => <>{item.row.es_apoyo==='1' ? 'SI' : ''}</> },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Eliminar"
          onClick={(e) => {
            // console.log(params)
            linkDeleteTutor(params.row.tutor_usuario_id);
          }}
          showInMenu
        />,
      ],
    },
  ];
  
  const linkDeleteTutor = (tutor_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar el tutor?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#afcb0d",
      cancelButtonColor: "#a5a5a5",
      icon: "warning",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        grupoTutoresDelete({usuario_id: usuario_id, grupo_id: grupo.id, tutor_id: tutor_id}).then((response) => {
  
          getGruposItem({usuario_id: usuario_id, grupo_id: grupo.id}).then( response => {
            setTutoresGrupo(response.grupo.tutores)
          })
  
        });
  
      } else if (result.isDenied) {
        // //   Swal.fire('Changes are not saved', '', 'info')
      }
    });
  };

  const handleOpenDialogTutorForm = () => {

    getGruposItem({
      usuario_id: usuario_id,
      grupo_id: grupo.id,
    }).then((response) => {

      setTutoresGrupo(response.grupo.tutores);

      setOpenDialogTutorForm(false);
    });

  };

  return (
    // <Box sx={{ marginX: 2, marginY: 0, marginBottom: 20 }}>
      // <Paper style={{ padding: 20 }}>
        <Box mb={2}>
          <Box mb={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Tutores
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  // setEntregaDoubleClick(null);
                  setOpenDialogTutorForm(true);
                }}
                style={{ marginLeft: 10 }}
              >
                Añadir tutor
              </Button>
            </Typography>

            <Divider />
          </Box>


            <div style={{ display: "flex", height: 700 }}>
              <DataGridPro
                pagination={false}
                rows={tutoresGrupo ? tutoresGrupo : []}
                columns={columnasTutores}
                rowHeight={30}
              />
            </div>

          <DialogTutorForm
            openModal={openDialogTutorForm}
            handleOpenModal={handleOpenDialogTutorForm}
            grupoId={grupo.id}
            tutores={tutores}
          />

        </Box>
      // </Paper>
    // </Box>
  );
}

/* -------------------------------------------------------------------------- */
/*                               ListadoEntregas                              */
/* -------------------------------------------------------------------------- */
const ListadoEntregas = ({usuario_id,grupo}) => {
  const [openDialogEntregaForm, setOpenDialogEntregaForm] = useState(false);
  const [entregaDoubleClick, setEntregaDoubleClick] = useState();
  const [entregas, setEntregas] = useState(grupo?.entregas);

  const columnasEntregas = [
    { field: "id", hide: true, headerName: "ID", minWidth: 10 },
    { field: "abreviatura", headerName: "Abrev.", minWidth: 50 },
    { field: "descripcion", headerName: "Descripción", flex: 1, minWidth: 250 },
    { field: "tipo_nombre", headerName: "Tipo", minWidth: 250 },
    {
      field: "fecha_planificacion",
      width: 200,
      headerName: "Fecha Planificación",
      description: "Fecha Planificación",
      type: "date",
      valueFormatter: (params) => {
        // console.log(params)
        return moment(params?.value).format("DD/MM/YYYY hh:mm A");
      },
      renderCell: (cellValues) => {
        return cellValues.value !== null &&
          cellValues.value !== "0000-00-00 00:00:00" ? (
          <Moment format="DD/MM/YYYY">{cellValues.value}</Moment>
        ) : (
          <></>
        );
      },
    },
    {
      field: "fecha_prevista",
      width: 200,
      headerName: "Fecha Prevista",
      description: "Fecha Prevista",
      type: "date",
      valueFormatter: (params) => {
        // console.log(params)
        return moment(params?.value).format("DD/MM/YYYY hh:mm A");
      },
      renderCell: (cellValues) => {
        return cellValues.value !== null &&
          cellValues.value !== "0000-00-00 00:00:00" ? (
          <Moment format="DD/MM/YYYY">{cellValues.value}</Moment>
        ) : (
          <></>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Eliminar"
          onClick={(e) => {
            // console.log(params)
            linkDeleteEntrega(usuario_id, params.id);
          }}
          showInMenu
        />,
      ],
    },
  ];
  
  const linkDeleteEntrega = (usuario_id, entrega_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar la entrega?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#afcb0d",
      cancelButtonColor: "#a5a5a5",
      icon: "warning",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        grupoEntregaDelete({usuario_id: usuario_id, grupo_entrega_id: entrega_id}).then((response) => {
          // cargarGrupo();
        });
  
      } else if (result.isDenied) {
        // //   Swal.fire('Changes are not saved', '', 'info')
      }
    });
  }

  return (
    // <Box sx={{ marginX: 2, marginY: 0, marginBottom: 20 }}>
      // <Paper style={{ padding: 20 }}>
        <Box mb={2}>
          <Box mb={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Listado de entregas
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setEntregaDoubleClick(null);
                  setOpenDialogEntregaForm(true);
                }}
                style={{ marginLeft: 10 }}
              >
                Añadir entrega
              </Button>
            </Typography>

            <Divider />
          </Box>

          {grupo?.entregas && (
            <div style={{ display: "flex", height: 700 }}>
              <DataGridPro
                pagination={false}
                rows={entregas}
                columns={columnasEntregas}
                rowHeight={30}
                onRowDoubleClick={(row) => {
                  setEntregaDoubleClick(row.row);
                  setOpenDialogEntregaForm(true);
                }}
              />
            </div>
          )}

          <DialogEntregaForm
            openModal={openDialogEntregaForm}
            handleOpenModal={() => {
              setOpenDialogEntregaForm(false);
            }}
            grupoId={grupo.id}
            setEntregas={setEntregas}
            entrega={entregaDoubleClick}
          />
        </Box>
      // </Paper>
    // </Box>
  );
}
//#endregion