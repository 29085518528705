import DateFnsUtils from '@date-io/date-fns';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from 'date-fns/locale/es';

import { useFormik } from "formik";
import * as yup from 'yup';
import { getAlumnosEntregas, getEntregasPlantillaTutor, getEntregasPlantillasByTutor, getGrupos, getTutoriasGrupales, getZooms, insertar_alumno_entrega, insertar_entrega_grupal, insertar_tutoria_grupal, update_alumno_entrega, update_tutoria_grupal } from '../../../services/alumnos';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getListaDetalle } from '../../../services/lista_detalle';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Swal from 'sweetalert2';
import { PlantillasPermisosService } from '../../../services/PlantillasPermisos.service';

export const DialogPlantillaPermisosForm = ({openModal, handleOpenModal}) => {
    const [zooms, setZooms] = useState([])
    // const [entregasPlantillaTutor, setEntregasPlantillaTutor] = useState([]);
    const [grupos, setGrupos] = useState([]);

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {

        formik.setValues({
            nombre: "",
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: {
            nombre: "",
        },
        validationSchema: yup.object({
            nombre: yup.string().required("Obligatorio"),
          }),
        onSubmit: (values) => {


            PlantillasPermisosService.insert({usuario_id: usuarioSeleccionado.id, campos: values}).then((response) => {

                Swal.fire({
                    title: 'Plantilla creada',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })

                handleOpenModal(false)

                formik.resetForm()
            })
            // Insertar
            
        },
    });

    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <Dialog open={openModal} onClose={() => handleOpenModal(false)} maxWidth={'md'}>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formik.handleSubmit}>

                <DialogTitle>Crear plantilla</DialogTitle>
                <DialogContent style={{width: '700px'}}>

                    <Grid container>

                        <Grid item sm={12}>

                            <TextField
                                id="nombre"
                                name="nombre"
                                label="Nombre"
                                placeholder="Introduce el nombre"
                                fullWidth
                                margin="normal"
                                value={formik.values.nombre}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                helperText={formik.touched.nombre && formik.errors.nombre}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleOpenModal(false)}>Cancelar</Button>
                    <Button type='submit'> Crear plantilla </Button>
                </DialogActions>
            </form>

        </Dialog>
    )
}